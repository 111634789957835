import React, { useState } from "react";
import { graphql } from "gatsby";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import Layout from "components/Layout/Layout";
import SupportLandingSection from "components/SupportLanding/SupportLandingSection";
import ContactUsContents from "components/ContactUsContents/ContactUsContents";
import SupportForm from "components/SupportForm/SupportForm";
import ButtonNav from "components/Design/Button/NavButton";
import Icon from "components/CommonIcon/Icon";
import PageContext from "contexts/PageContext";
import { AYANA_BALI } from "store/constants/hotel";
import { BALI } from "store/constants/hotel";
import ReachUs from "components/ReachUs/ReachUs";
import useQueryParams from "hooks/useQueryParams";
import { AYANA_BALI_WHATSAPP_LINK } from "store/constants/socmedLink";
import { WECHAT_LINK } from "store/constants/link";
import { transformObject } from "utils/transformv4";

const Support = ({ data }) => {
  const { reason, destination, subDestination } = useQueryParams();
  const { language } = useI18next();
  const { t } = useTranslation(["homepage", "support"]);
  const [supportReason, setSupportReason] = useState(reason);
  const [supportDestination, setSupportDestination] = useState(destination);
  const [supportSubDestination, setSupportSubDestination] =
    useState(subDestination);

  const {
    cms,
    allNavigationMenuJson: {
      nodes: [navigations],
    },
    allSocialMediaJson: {
      nodes: [socialMediaList],
    },
  } = data;

  const {
    sections: [destinations],
    hotelsByLocale: [hotel],
    hotelBali,
    pagesByLocale: [{ seo_tags }],
    supportDrawer: [supportDrawer],
    supportRecipientEmails: recipientEmail,
    hotelPropertyBali,
  } = transformObject(cms);

  const restaurants = hotelBali
    .map((hotel) => hotel.restaurants)
    .flat()
    .filter((restaurant) => restaurant.title_en !== "Rock Bar BALI");

  const uniqueRestaurants = Array.from(
    new Set(restaurants.map((a) => a.id))
  ).map((id) => {
    return restaurants.find((a) => a.id === id);
  });

  const activities = hotelBali.map((hotel) => hotel.activities).flat();
  const weddingVenues = hotelBali.map((hotel) => hotel.wedding_venues).flat();
  const allWeddingPackages = weddingVenues
    .map((venue) => venue.wedding_packages)
    .flat();
  const weddingPackages = allWeddingPackages.filter(
    (weddingPackage, index, array) =>
      array.findIndex((t) => t.title_en === weddingPackage.title_en) === index
  );

  //get unique spa
  const spas = [...new Set(...hotelBali.map((hotel) => hotel.spas))];
  const supportDrawerData = {
    ...supportDrawer,
    hotels: hotelPropertyBali,
    hotel: hotel,
  };

  const reachUsLink =
    language === "zh" ? WECHAT_LINK : AYANA_BALI_WHATSAPP_LINK;

  return (
    <PageContext.Provider
      value={{
        location: AYANA_BALI,
        reason: supportReason,
        destination: supportDestination,
        subDestination: supportSubDestination,
      }}>
      <Layout
        whatsAppLink={AYANA_BALI_WHATSAPP_LINK}
        destination={t("Bali")}
        seoTitle={t("Ayana Support")}
        seoTags={seo_tags}
        destinationCards={destinations.images}
        navigations={navigations}
        hotel={{ ...hotel, ...hotel.destinations[0] }}
        socialMedia={socialMediaList}
        socialMediaLabel={t("Connect with AYANA Bali")}
        supportDrawer={supportDrawerData}
        hotelPropertyBali={hotelPropertyBali}>
        <SupportLandingSection
          title={t("Ayana Support")}
          contactUsContents={<ContactUsContents hotels={hotel.offices} />}
          otherContents={
            <>
              <ReachUs supportReachUsLink={reachUsLink} />
              <ButtonNav
                href="/bali/faq"
                prefixIcon={<Icon name="comment" />}
                label={t("FAQ")}
              />
            </>
          }
          supportForm={
            <SupportForm
              reason={reason}
              setReason={setSupportReason}
              destination={destination}
              setDestination={setSupportDestination}
              subDestination={subDestination}
              setSubDestination={setSupportSubDestination}
              location={BALI}
              recipients={recipientEmail}
              activities={activities}
              weddingVenues={weddingVenues}
              weddingPackages={weddingPackages}
              spas={spas}
              dinings={uniqueRestaurants}
              navigateBackTo="/bali/thank-you"
              hotels={hotelBali}
            />
          }
        />
      </Layout>
    </PageContext.Provider>
  );
};

export const query = graphql`
  query BaliSupport($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allNavigationMenuJson(filter: { hotel: { eq: "AYANA Bali" } }) {
      ...LocalNavigation
    }
    allSocialMediaJson(filter: { hotel: { eq: "AYANA Bali" } }) {
      nodes {
        hotel
        socmed {
          link {
            en
            ja
          }
          iconName
        }
      }
    }

    cms {
      sections(filters: { name: { eq: "Bali_Footer_Destination" } }) {
        ...NavSections
      }
      supportDrawer: sections(
        filters: { name: { eq: "Bali_Support_Drawer" } }
      ) {
        ...NavSections
      }
      hotelsByLocale(language: $language, where: { hotel_code: "ARSB" }) {
        data {
          id
          attributes {
            ...NavHotel
          }
        }
      }
      pagesByLocale(language: $language, where: { name: "Bali_Support" }) {
        data {
          id
          attributes {
            ...CmsPageSeoTags_v4
          }
        }
      }
      hotelBali: hotelsByLocale(
        language: $language
        where: { location: "Bali" }
      ) {
        data {
          id
          attributes {
            title
            title_en
            hotel_code
            restaurants {
              data {
                id
                attributes {
                  title
                  title_en
                  hotels {
                    data {
                      id
                      attributes {
                        hotel_code
                        title_en
                      }
                    }
                  }
                  operating_hours {
                    id
                    day
                    closing_time
                    opening_time
                  }
                  sequence
                }
              }
            }
            activities {
              data {
                id
                attributes {
                  title
                  title_en
                }
              }
            }
            spas {
              data {
                id
                attributes {
                  title
                  title_en
                  hotels {
                    data {
                      id
                      attributes {
                        hotel_code
                      }
                    }
                  }
                  spa_treatments {
                    data {
                      id
                      attributes {
                        title
                        title_en
                        operating_hours {
                          id
                          day
                          closing_time
                          opening_time
                        }
                        sequence
                      }
                    }
                  }
                  sequence
                }
              }
            }
            wedding_venues(sort: "sequence") {
              data {
                id
                attributes {
                  title
                  title_en
                  wedding_packages {
                    data {
                      id
                      attributes {
                        title
                        title_en
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      supportRecipientEmails(filters: { location: { eq: "BALI" } }) {
        data {
          id
          attributes {
            reason
            location
            emails {
              id
              language
              email
              hotel {
                data {
                  id
                }
              }
            }
          }
        }
      }
      hotelPropertyBali: hotelsByLocale(
        language: $language
        where: { id: [1, 2, 3, 9] }
        sort: "id:DESC"
      ) {
        data {
          id
          attributes {
            name
            title
            logo {
              ...CmsUploadFileEntityResponse
            }
            offices(sort: "sequence") {
              ...CmsOffices_v4
            }
          }
        }
      }
    }
  }
`;
export default Support;
